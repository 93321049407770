<template>
  <section>
    <v-dialog
      v-model="showModal"
      max-width="800px"
    >
      <v-card
        color="#fff"
      >
        <br>
        <h2 class="title-style">
          <v-icon class="icon-style">
            mdi-alert
          </v-icon> Create Ticket
        </h2>
        <v-form
          ref="form"
          v-model="isFormValid"
        >
          <v-container fluid>
            <v-card-text class="text-body-1 text-center">
              <v-row class="text-center">
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    v-model="ticketName"
                    label="Ticket Name*"
                    hint="Name for your reference - 'App users not listing' (Only alpha dashes allowed)."
                    :rules="[(v) => !!v || 'This field is required']"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="clientReferenceNumber"
                    label="Reference Number"
                    hint="Reference number for your easiness to identify ticket."
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-select
                    v-model="selectedType"
                    :items="type"
                    label="Select Type*"
                    :rules="rules.select"
                    item-text="name"
                    item-value="id"
                    menu-props="auto"
                    prepend-icon="mdi-select"
                  />
                </v-col>
                <br>
                <v-textarea
                  v-model="issueDescription"
                  filled
                  label="Description about the issue*"
                  :rules="[(v) => !!v || 'This field is required']"
                />
              </v-row>
              <br>
              <p class="text-sm-left">
                *Indicates required field
              </p>
            </v-card-text>
          </v-container>
        </v-form>
        <v-card-actions>
          <v-btn
            class="mt-2"
            color="#000"
            @click="submit()"
          >
            Create
          </v-btn>
          <v-btn
            class="mt-2"
            color="#aaa"
            @click="showModal = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
      <centre-spinner
        :loading="loading"
      />
    </v-dialog>
  </section>
</template>

<script>
import Constants from 'src/constants';
import spinner from 'src/views/dashboard/component/SpinnerCentre';

  export default {
    name: 'SupportForm',
    components: {
      'centre-spinner': spinner,
    },
    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        showModal: true,
        loading: false,
        isFormValid: false,
        ticketName: '',
        issueDescription: '',
        selectedDigiBoardId: null,
        imageFile: null,
        selectedType: '',
        type: [
          {
            name: 'SaaS',
            id: 'saas',
          },
          {
            name: 'App',
            id: 'app',
          },
        ],
        clientReferenceNumber: null,
        rules: {
          select: [(v) => !!v || 'This field is required'],
        },
      };
    },
    computed: {
      deviceImageFile () {
        return this.$store.getters['mediafile/getMediaFile'];
      },
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    methods: {
      async submit () {
        if (this.$refs.form.validate()) {
          if (this.imageFile !== null) {
            this.loading = true;
            const formData = new FormData();
            formData.append('media_file', this.imageFile);
            formData.append('module_type', Constants.MODULE_SUPPORT);
            await this.$store.dispatch('mediafile/upload', formData);
            this.$store.dispatch('support/createIssueTicket', {
              name: this.ticketName,
              type: this.selectedType,
              digi_board_id: null,
              description: this.issueDescription,
              media_file_id: this.deviceImageFile.id,
              client_reference: this.clientReferenceNumber,
            }).then(response => {
              this.$store.dispatch('alert/onAlert', {
                message: 'Ticket created successfully.',
                type: Constants.ALERT_TYPE_SUCCESS,
              });
              this.$store.dispatch('support/clearIssueList');
              this.$emit('get-latest-tickets');
              this.loading = false;
              this.close();
            }).catch(() => {
              this.loading = false;
            });
          } else {
            this.loading = true;
            this.$store.dispatch('support/createIssueTicket', {
              name: this.ticketName,
              type: this.selectedType,
              digi_board_id: null,
              description: this.issueDescription,
              client_reference: this.clientReferenceNumber,
            }).then(response => {
              this.$store.dispatch('alert/onAlert', {
                message: 'Ticket created successfully.',
                type: Constants.ALERT_TYPE_SUCCESS,
              });
              this.$store.dispatch('support/clearIssueList');
              this.$emit('get-latest-tickets');
              this.loading = false;
              this.close();
            }).catch(() => {
              this.loading = false;
            });
          }
        }
      },
      close () {
        this.showModal = false;
        this.ticketName = '';
        this.issueDescription = '';
        this.selectedDigiBoardId = null;
        this.imageFile = null;
        this.clientReferenceNumber = null;
        this.selectedType = '';
      },
      typeMapper () {
        if (this.selectedType === 'APP') {
          return 'app';
        }

        return 'saas';
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
</style>
